<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
	import Carousel from '$lib/components/Carousel.svelte';
	import TopNews from '$lib/components/top/TopNews.svelte';
	import type { PageData } from './$types';
	import TopCard from '$lib/components/top/TopCard.svelte';
	import CarouselPagination from '$lib/components/CarouselPagination.svelte';
    import { writable } from 'svelte/store';
	import { assets, base } from '$app/paths';
	import shuffleArray from '$lib/utilities/shuffle-array';
    import Image from '$lib/components/Image.svelte';

	export let data: PageData;
	const seed = new Date().getMilliseconds();
	$: carousels = shuffleArray(data.carousels, seed);
	let carouselIndex = 0;
    const carouselNavIndex = writable(0);
</script>

<section id="top-carousel">
	<div class="carousel-bg">
		<div class="carousel-wrapper">
			<Image isTransparent={true} src="{assets}/img/pages/top/carousel/carousel_bg" alt="" />
			<div class="carousel-main-wrapper">
				<Carousel {carouselIndex} {carouselNavIndex}>
					{#each carousels as creator}
						<div class="slide-wrapper">
							<Image
								class="carousel-bg-image"
								src="{assets}/img/artist/carousel/{creator.link}"
								alt={creator.name}
							/>
							<div class="carousel-overlay {creator.align}">
								<div class="carousel-overlay-block {creator.align}">
									<a class="name-link" href="{base}/artists/{creator.link}">
										<h1 class="name-jp-default {creator.theme}">{creator.name}</h1>
										<p class="name-eng name-en-default">{creator.name_eng}</p>
									</a>
									<p class="job {creator.theme}">{creator.jobs.join(' / ')}</p>
									<a href="{base}/artists/{creator.link}"><img src="{assets}/img/pages/top/carousel/artist_detail.svg" alt="Artist detail" /></a>
								</div>
							</div>
						</div>
					{/each}
				</Carousel>
			</div>
		</div>
	</div>
	<CarouselPagination carouselLength={carousels.length} {carouselNavIndex} on:updateIndex={index => carouselIndex = index.detail} />
</section>
<section id="top-panels" class="global-width-limit">
	<div class="card-block">
		{#each data.cards as card}
			<TopCard {card} />
		{/each}
	</div>
</section>
<section id="top-news" class="global-width-limit">
	<div class="content-header">
		<h1>News</h1>
		<hr />
	</div>
	<div class="news-block global-width-limit">
		{#each data.news as article}
			<TopNews
				title={article.title}
				thumbnail={article.img}
				body={article.body}
				link={article.link}
				date={article.date}
			/>
		{/each}
	</div>
</section>

<style lang="scss">
	@use '$lib/assets/css/mq-settings' as mq;
    @use '$lib/assets/css/variables' as var;

	#top-carousel {
		margin-bottom: 10px;
	}
	#top-carousel,
	.carousel-bg {
		width: 100%;
	}
	.carousel-bg {
		background-color: #b7a861;
		margin-bottom: 5px;
	}
	.carousel-wrapper {
		position: relative;
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
		overflow: hidden;
	}
	.carousel-main-wrapper {
		position: absolute;
		top: 0;
		margin: 0;
	}
	:global(.carousel-bg-image) {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		min-height: 240px;
	}
	.slide-wrapper {
		position: relative;
	}
	.carousel-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		padding: 0 9%;
		align-items: center;
		&.left {
			justify-content: flex-start;
		}
		&.right {
			justify-content: flex-end;
		}
	}
	.carousel-overlay-block {
		display: flex;
		flex-direction: column;
		.name-link {
			display: contents;
		}
		h1 {
			font-size: 38px;
			margin-bottom: 6.4px;
			@include mq.mq($until: tablet) {
				font-size: 27px;
			}
		}
		.name-eng {
			@include mq.mq($until: tablet) {
				font-size: 12px;
			}
		}
		.job {
			font-size: 16px;
			margin: 12px 0 24px;
			font-family: var.$font-base;
			font-weight: 700;
			@include mq.mq($until: tablet) {
				font-size: 12px;
				margin: 8px 0 16px;
			}
		}
		img {
			width: 255px;
			@include mq.mq($until: tablet) {
				width: 180px;
			}
			@include var.hover-transparent-weak;
		}
		.light {
			color: white;
		}
		.dark {
			color: var.$color-dark-brown;
		}
		&.left {
			align-items: flex-start;
		}
		&.right {
			align-items: flex-end;
		}
	}
	#top-panels {
		margin: 10px 0;
	}
	.card-block {
		display: grid;
		grid-template-columns: 338px 338px 338px;
		gap: 30px;
		place-content: center;
		margin: 30px;
		@include mq.mq($until: wide) {
			grid-template-columns: 338px 338px;
		}
		@include mq.mq($until: desktop) {
			grid-template-columns: 338px;
		}
	}
    .content-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 3.7px;
			color: var.$color-font-base;
        }
        hr {
            width: 50px;
            height: 3px;
            background-color: var.$color-accent;
            margin: 16px auto;
        }
    }
    .news-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        padding: 40px 30px;
		@include mq.mq($until: desktop) {
			grid-template-columns: 1fr;
		}
    }
</style>
