<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
import { assets } from "$app/paths";
import Image from '$lib/components/Image.svelte';

export let title: string;
export let thumbnail: string;
export let body: string;
export let link: string;
export let date: string;
</script>

<a class="news-container" href={link} target="_blank">
    <div class="thumbnail-wrapper">
        <Image class="thumbnail" src="{assets}/img/news/{thumbnail}" alt="" />
    </div>
    <div class="news-content">
        <p class="news-date">{date}</p>
        <p class="news-body">{body}</p>
    </div>
</a>

<style lang="scss">
    @use '$lib/assets/css/mq-settings' as mq;
    @use '$lib/assets/css/variables' as var;
    $base-height: 181.65px;

    .news-container {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        min-width: 0;
        *:not(.thumbnail) {
            transition: opacity 0.3s ease;
        }
        &:hover *:not(.thumbnail) {
            opacity: 0.9;
        }
        &:hover :global(.thumbnail) {
            transform: scale(1);
            @include var.hover-lighter-weak-base;
        }
    }
    .thumbnail-wrapper {
        width: $base-height;
        height: $base-height;
        outline-style: solid;
        outline-color: var.$color-accent;
        outline-width: 3px;
        flex-shrink: 0;
        overflow: hidden;
        @include mq.mq($until: tablet) {
            display: none;
        }
    }
    :global(.thumbnail) {
        transform: scale(1.05);
        transition: transform 0.3s ease, filter 0.3s ease;
        
    }
    .news-content {
        display: flex;
        flex-direction: column;
        margin: 10px 20px;
        margin-right: 0;
        @include mq.mq($until: tablet) {
            margin-left: 0;
        }
    }
    .news-date {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 3.7px;
        color: var.$color-accent;
    }
    .news-body {
        margin-top: 5px;
        text-decoration-line: underline;
        color: var.$color-basic-font;
        font-weight: 400;
    }
</style>