<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
	import Balloon from '../Balloon.svelte';
	import OctaCard from '../OctaCard.svelte';
	import { assets } from '$app/paths';
	import styleVariables from '$lib/style-variables';
    import Image from '$lib/components/Image.svelte';

	export let card: {
		name: string;
		name_eng: string;
		hover: string;
		is_sns: boolean;
		img: string | null;
		link: string;
	};
	let hovered = false;
</script>

<div class="top-card-wrapper">
	<OctaCard bevelSize={20} thickness={2} color="#A8953E">
		<nav class="card-content-wrapper" on:mouseenter={() => hovered = true} on:mouseleave={() => hovered = false}>
			{#if !card.is_sns}
				<a class="card-common upper-card" href={card.link}>
					<h1 class="card-name">{card.name}</h1>
					<p class="font-base">{card.name_eng}</p>
					<Image class="top-card-img" src="{assets}/img/pages/top/card/{card.img}" alt="" />
				</a>
			{:else}
				<a class="card-common lower-card" href={card.link} target="_blank">
					<p class="ts">TOMARIGITEI's</p>
					<p class="main">{card.name} <Image isTransparent={true} src="{assets}/img/common/new_window" alt="" /></p>
				</a>
			{/if}
		</nav>
	</OctaCard>
	<div class="hover-overlay" class:show={hovered}>
		<Balloon 
            align="right" 
            triangleWidth="10px"
            triangleHeight="12px"
            triangleOffset="-14px"
            backgroundColor={styleVariables.colorDarkBrown}
        >
			<div class="hover-text-wrapper">
				<p class="hover-text font-base">{card.hover}</p>
			</div>
		</Balloon>
		<Image isTransparent={true} class="goichan" src="{assets}/img/common/goi_chan" alt="" />
	</div>
</div>

<style lang="scss">
    @use '$lib/assets/css/variables' as var;

	a {
		text-decoration: none;
	}
	.top-card-wrapper {
		position: relative;
		height: 200px;
	}
	.card-content-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		background-color: var.$color-light-bg;
	}
	.card-common {
		text-align: center;
		cursor: pointer;
		width: 100%;
		height: 100%;
		display: grid;
		place-content: center;
	}
	.upper-card {
		* {
			transition: text-shadow 0.5s ease;
		}
		h1 {
            position: relative;
			font-size: 28.8px;
			font-feature-settings: 'palt';
            z-index: 10;
		}
		p {
            position: relative;
            color: var.$color-accent;
			font-size: 12px;
			letter-spacing: 0.8px;
            z-index: 10;
		}
		:global(.top-card-img) {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			z-index: 0;
			transform: scale(1.01);
		}
		&:hover * {
			$color: #fff7d3aa;
            $blur-radius: 10px;
			text-shadow: $color 1px 1px $blur-radius, $color 1px -1px $blur-radius, $color -1px 1px $blur-radius, $color -1px -1px $blur-radius
		}
	}
	.card-name {
		font-family: var.$font-maru;
		font-weight: 700;
		color: var.$color-font-base;
	}
	.lower-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		> * {
			font-weight: 700;
			letter-spacing: 3.68px;
			color: var.$color-font-base;
		}
		.main {
			display: flex;
			align-items: center;
			font-size: 32px;
		}
		img {
			width: 16px;
		}
	}
	.hover-overlay {
		position: absolute;
		width: 100%;
		display: flex;
		z-index: 100;
		bottom: -20px;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease;
	}
	.hover-text-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.hover-text {
		color: white;
		font-size: 14px;
		margin: 0;
		padding: 10px 15px;
	}
	:global(.goichan) {
		height: 66px;
	}
	.show {
		opacity: 1;
	}
</style>
