// Copyright 2023 HALBY
// See LICENSE file for details.

// 線形合同法の実装
class LCG
{
    private a = 1664525; // 乗数
    private c = 1013904223; // 加算定数
    private m = 4294967296; // 法（2^32）
    private seed: number;

    constructor(seed: number)
    {
        this.seed = seed % this.m;
    }

    // 疑似ランダム数を生成するメソッド
    public next(): number
    {
        this.seed = (this.a * this.seed + this.c) % this.m;
        return this.seed / this.m;
    }
}

// 配列をシャッフルする関数（Fisher-Yatesアルゴリズムを使用）
export default function shuffleArray<T>(array: T[], seed: number): T[]
{
    const rng = new LCG(seed);
    const result = [...array];

    for (let i = result.length - 1; i > 0; i--)
    {
        // LCGからの疑似ランダム数を使用して、0以上i以下の整数を生成
        const j = Math.floor(rng.next() * (i + 1));
        // i番目とj番目を交換
        [result[i], result[j]] = [result[j], result[i]];
    }

    return result;
}